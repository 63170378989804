import {
  Container,
  Row,
  Col,
  Stack,
  Card,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Roulette from "../components/Roulette";
import {
  HeroImage,
  HeroImageShadow,
  HeroImageFlares,
  MeuCarrefourLogo,
  CartaoCarrefourLogo,
  CarrefourLogo,
  CarrefourBairroLogo,
  NacionalLogo,
  BomPrecoLogo,
  AbrilImbativelBanner,
  CloudImage,
  LeftLightImage,
  RightLightImage,
} from "../components/Images";
import { Suspense } from "react";

export function Component() {
  const navigate = useNavigate();

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section id="inicio" className="hero w-100">
        <div className="hero__left-light" style={{
            backgroundImage: `image-set(
            url(${LeftLightImage}) type("image/webp"),
            url(../assets/images/left-light.png) type("image/jpeg")
          )`,
          }}></div>
        <div className="hero__right-light" style={{
            backgroundImage: `image-set(
            url(${RightLightImage}) type("image/webp"),
            url(../assets/images/right-light.png) type("image/jpeg")
          )`,
          }}></div>
        <div
          className="hero__clouds"
          style={{
            backgroundImage: `image-set(
            url(${CloudImage}) type("image/webp"),
            url(../assets/images/cloud.png) type("image/jpeg")
          )`,
          }}
        ></div>

        <Container>
          <Row>
            <Col
              xs={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 6, offset: 0 }}
              className="d-flex flex-column justify-content-between hero__left"
            >
              <div className="hero__image">
                <Image
                  className="hero__left__logo"
                  src={HeroImage}
                  alt=""
                  fluid
                />

                <Image
                  className="hero__left__shadow"
                  src={HeroImageShadow}
                  alt=""
                  fluid
                />

                <Image
                  className="hero__left__flares"
                  src={HeroImageFlares}
                  alt=""
                  fluid
                />
              </div>

              <Row
                className="mb-5 mb-lg-0 d-flex align-items-start"
                style={{ height: 96 }}
              >
                <Col xs={12} md={6} className="mb-4 mb-md-0">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="d-flex align-items-center"
                  >
                    <Image
                      width={60}
                      height={60}
                      src={MeuCarrefourLogo}
                      alt="Logo Meu Carrefour"
                      fluid
                    />

                    <span>
                      Clientes Meu Carrefour
                      <br />
                      <span className="text-gold fw-bold">
                        têm mais chances
                        <br />
                        de ganhar.
                      </span>
                    </span>
                  </Stack>
                </Col>
                <Col xs={12} md={6} className="mb-4 mb-md-0">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="d-flex align-items-center"
                  >
                    <Image
                      width={80}
                      height={40}
                      src={CartaoCarrefourLogo}
                      alt="Logo Cartão Carrefour"
                      fluid
                    />

                    <span>
                      Pague com o<br />
                      Cartão Carrefour e<br />
                      <span className="text-gold fw-bold">
                        dobre suas chances.
                      </span>
                    </span>
                  </Stack>
                </Col>
              </Row>
            </Col>

            <Col
              xs={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 6, offset: 0 }}
              className="d-flex flex-column justify-content-between hero__right ps-0 ps-lg-5 mt-5 mt-md-2 mt-lg-0"
            >
              <p className="hero__message mt-lg-5">
                Nas compras
                <br />
                a partir de R$ 150,00
                <br />
                <span className="text-gold fw-bold">
                  concorra a 1 ano
                  <br />
                  de compras grátis
                  <br />
                  no valor de R$ 1.000,00
                  <br />
                  todo mês.*
                </span>
              </p>

              <Stack
                direction="horizontal"
                className="d-flex flex-row align-items-end justify-content-start hero__roulette"
              >
                <p className="roleta-description">
                  E AINDA:
                  <br />
                  CADASTRE
                  <br />
                  A NOTA FISCAL,
                  <br />
                  <span className="fw-bold">
                    GIRE E GANHE**
                    <br />
                    NA HORA NA
                    <br />
                    ROLETA DA SORTE!
                  </span>
                </p>

                <Roulette className="rotate" />
              </Stack>
            </Col>
          </Row>

          <Row className="justify-content-around mt-5 pt-5 pt-lg-0 logos">
            <Col
              xs={6}
              md={3}
              className="logo mb-4 d-flex justify-content-start"
            >
              <Image height={40} src={CarrefourLogo} alt="Logo Carrefour" />
            </Col>
            <Col xs={6} md={3} className="logo mb-4 d-flex justify-content-end">
              <Image
                height={70}
                src={CarrefourBairroLogo}
                alt="Logo Carrefour Bairro"
              />
            </Col>
            <Col xs={6} md={3} className="logo mb-4 d-flex justify-content-end">
              <Image height={50} src={NacionalLogo} alt="Logo Nacional" />
            </Col>
            <Col xs={6} md={3} className="logo mb-4 d-flex justify-content-end">
              <Image height={50} src={BomPrecoLogo} alt="Logo Bom Preço" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white">
        <Container className="d-flex">
          <a
            title="Abril Imbatível Carrefour"
            href="https://carrinhocheiooanointeiro.com.br/?&utm_source=carrinho-cheio&utm_campaign=abril-imbativel&utm_term=banner-site"
            className="mx-auto"
          >
            <Image
              src={AbrilImbativelBanner}
              alt="Abril Imbativel Banner"
              width={800}
              height={100}
              fluid
            />
          </a>
        </Container>
      </section>

      <section id="como-participar" className="bg-white">
        <h1 className="section-title text-danger">
          Carrinho Cheio o Ano Inteiro
        </h1>
        <span className="section-subtitle mb-5">
          Confira como é fácil participar:
        </span>

        <Col lg={8} className="offset-lg-2 mb-5">
          <Row xs={1} md={2} className="g-4">
            <Col>
              <Card className="step-card">
                <Card.Body>
                  <span className="step-card__number">1</span>
                  <span className="step-card__text">
                    Compre acima de <b>R$ 150,00</b> nas lojas Carrefour e redes
                    participantes
                  </span>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="step-card">
                <Card.Body>
                  <span className="step-card__number">2</span>
                  <span className="step-card__text">
                    Cadastre seu cupom fiscal aqui no site de <b>02/04/2024</b>{" "}
                    a <b>29/04/2024</b> e receba seu número da sorte
                  </span>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="step-card">
                <Card.Body>
                  <span className="step-card__number">3</span>
                  <span className="step-card__text">
                    <b>Pronto!</b> Você já está concorrendo ao prêmio!
                  </span>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="step-card">
                <Card.Body>
                  <span className="step-card__number">4</span>
                  <span className="step-card__text">
                    E você ainda ganha descontos girando a{" "}
                    <b>roleta da sorte</b>!
                  </span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        <div className="d-flex">
          <Button
            variant="danger"
            className="mx-auto"
            onClick={() => navigate("/cadastrar")}
          >
            Cadastre seu cupom ou nota fiscal aqui
          </Button>
        </div>
      </section>

      <section id="duvidas" className="dark">
        <Container>
          <h1 className="section-title">Dúvidas</h1>
          <span className="section-subtitle mb-5 text-white">
            Abaixo citamos dúvidas frenquentes
          </span>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                1. Como faço para participar da promoção "Carrinho Cheio o Ano
                Inteiro"?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Para participar da promoção o consumidor deverá efetuar
                  compras a partir de R$ 150,00 (cento e cinquenta reais) nas
                  lojas Carrefour, Carrefour Bairro, Bom Preço e Nacional, no
                  período de 02.04.2024 a 29.04.2024.
                </p>

                <p>
                  Na modalidade sorteio o consumidor deverá acessar o site{" "}
                  <a
                    href="https://www.carrinhocheiooanointeiro.com.br"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    www.carrinhocheiooanointeiro.com.br
                  </a>{" "}
                  e efetuar seu cadastramento (pessoal e de compra), preenchendo
                  todos os campos disponibilizados. Terá direito a 1 (um) número
                  da sorte, caso o consumidor seja membro do programa Meu
                  Carrefour e/ou faça o pagamento da compra utilizando o Cartão
                  CRF, o consumidor receberá o dobro de números da sorte. Na
                  modalidade compre e ganhe o consumidor deverá acessar o site{" "}
                  <a
                    href="https://www.carrinhocheiooanointeiro.com.br"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    www.carrinhocheiooanointeiro.com.br
                  </a>{" "}
                  e efetuar seu cadastramento (pessoal e de compra), preenchendo
                  todos os campos disponibilizados. A partir de R$ 150,00 (cento
                  e cinquenta reais) em compras realizadas nas lojas Carrefour,
                  Carrefour Bairro, Nacional e Bom Preço, o consumidor poderá
                  girar a roleta virtual para descobrir o brinde que faz jus,
                  caso o consumidor seja membro do Programa Meu Carrefour ou
                  faça o pagamento da compra utilizando o Cartão CRF, o
                  consumidor poderá girar a roleta 2 (duas) vezes.
                </p>

                <p>
                  Na modalidade compre e ganhe todas as participações dão
                  direito a um dos prêmios estabelecidos em regulamento, após o
                  cadastro o consumidor terá o direito de girar a roleta para
                  saber qual foi o brinde, caso o consumidor faça o pagamento da
                  compra utilizando o Cartão CRF, poderá girar a roleta 2 (duas)
                  vezes.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                2. Quem pode participar da promoção?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Podem participar desta promoção todos os consumidores, pessoas
                  físicas, com idade igual ou superior a 18 (dezoito) anos que
                  efetuarem compras a partir de R$ 150,00 (Cento e cinquenta
                  reais) nas lojas lojas Carrefour, Carrefour Bairro, Bom Preço
                  e Nacional, no período de 02.04.2024 a 29.04.2024, desde que
                  obedecidos os demais requisitos do Regulamento.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                3. Quem não pode participar da promoção?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  É vedada a participação nesta PROMOÇÃO de qualquer funcionário
                  ou membro do corpo diretivo pertencente a empresa Promotora
                  Mandatária, empresa Promotora Aderentes, Promosorte
                  Consultoria em Promoções Ltda, Comuniclick e qualquer outra
                  empresa envolvida na operacionalização da promoção e/ou na sua
                  premiação.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="d-flex">
            <Button
              variant="link"
              className="mx-auto mt-5 text-white"
              onClick={() => navigate("/duvidas")}
            >
              Exibir mais dúvidas
            </Button>
          </div>
        </Container>
      </section>
    </Suspense>
  );
}
